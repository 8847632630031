.modal-container {
    direction: rtl;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 24;
    padding: 30px;
    @media screen and (max-width: 500px) {
        width: 80%;
    }
}
.action-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.loading-button {
    direction: ltr;
    margin-left: 10px !important;
}