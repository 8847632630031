.table-container {
    height: 800px; 
    width: 95vw; 
    margin-top: 10px; 
    display: flex; 
    flex-Direction: column;
    @media screen and (max-width: 500px) {
        height: 600px;
    }
}

.hide {
    display: none;
}

.show {
    display: block;
}
.super-app-theme--כן {
    background-color: #ff004c73;
}